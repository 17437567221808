import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { NavigationService } from '../navigation/navigation.service';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private store: BehaviorSubject<any> = new BehaviorSubject([]);
  private profile: BehaviorSubject<any> = new BehaviorSubject([]);
  private token: BehaviorSubject<String> = new BehaviorSubject('');
  private role: BehaviorSubject<String> = new BehaviorSubject('');
  
  private has_discount: BehaviorSubject<String> = new BehaviorSubject('');  
  private master_type: BehaviorSubject<String> = new BehaviorSubject('');  
  private master_create_users: BehaviorSubject<String> = new BehaviorSubject('');  
  
  constructor(private navigationService: NavigationService) {}

  setData(data: any) {
    this.setItem('store', JSON.stringify(data.store));
    this.setItem('profile', JSON.stringify(data.profile));
    this.setItem('role', JSON.stringify(data.role));
    this.role.next(data.role);
    this.store.next(data.store);
    this.profile.next(data.profile);
    this.setItem('has_discount', JSON.stringify(data.has_discount));
    this.has_discount.next(data.has_discount);

    this.setItem('master_type', JSON.stringify(data.type));
    this.master_type.next(data.type);

    this.setItem('master_create_users', JSON.stringify(data.created_users));
    this.master_create_users.next(data.created_users);

  }

  getItem(key: string): any {
    return localStorage.getItem(key);
  }

  reloadData() : void {
    this.getItem('token')
    .then((token : any) => {
      this.token.next(token);
    });

    this.getItem('role')
    .then((role : any) => {
      this.role.next(role);
    });
  }

  getToken(): any {
    return this.token;
  }

  setToken(token: string): any {
    this.setItem('token', token);
    this.token.next(token)
  }

  setItem(key: string, value: any): void {
    localStorage.setItem(key, value);
  }

  getRole(): Observable<any> {
    return this.role;
  }

  setRole(role: string): any {
    this.setItem('role', role);
    this.role.next(role);
  }

  getHas_discount(): Observable<any> {
    return this.has_discount;
  }

  SetHas_discount(has_discount: string): any {
    this.setItem('has_discount', has_discount);
    this.has_discount.next(has_discount);
  }

  getMasterType(): Observable<any> {
    return this.master_type;
  }

  setMasterType(master_type: string): any {
    this.setItem('master_type', master_type);
    this.master_type.next(master_type);
  }

  getmaster_create_users(): Observable<any> {
    return this.master_type;
  }

  setmaster_create_users(master_create_users: string): any {
    this.setItem('master_create_users', master_create_users);
    this.master_create_users.next(master_create_users);
  }

  clear() {
    localStorage.clear();
    this.navigationService.navigate('login');
  }

  isAuthenticated(): boolean {
    const token = this.getItem('token');

    if (token) {
      this.token.next(token);
    }

    const role = this.getItem('role');

    if (role) {
      this.role.next(role);
    }

    return token ? true : false;
  }



}
