import { Component } from '@angular/core';
import { ModalService } from 'src/app/service/modal/modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent<T> {

  display = true;
  componentName:string = ''

  constructor(
    private modalService: ModalService<T>
  ) {
    this.componentName = this.modalService.nameComponent
    console.log(this.componentName)
  }

  async close(): Promise<void> {
    this.display = false;

    setTimeout(async () => {
      await this.modalService.close();
    }, 300);
  }

}
