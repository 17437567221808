import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import { ConfigGeneralService } from '../configGeneral/config-general.service';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  listImagesBanner: BehaviorSubject<any[]> = new BehaviorSubject([])

  bannersHeader : BehaviorSubject<any[]> = new BehaviorSubject([])
  bannersMiddle : BehaviorSubject<any[]> = new BehaviorSubject([])
  bannersEnd : BehaviorSubject<any[]> = new BehaviorSubject([])
  lastSection: BehaviorSubject<any[]> = new BehaviorSubject([])

  products: BehaviorSubject<any[]> = new BehaviorSubject([])
  productselected: BehaviorSubject<number> = new BehaviorSubject(0)
  slider: BehaviorSubject<String> = new BehaviorSubject('')

  imageSelected: BehaviorSubject<string> = new BehaviorSubject('')

  firstImage: BehaviorSubject<boolean> = new BehaviorSubject(false)
  firstImgToUpload: BehaviorSubject<any> = new BehaviorSubject({})
  secondImgToUpload: BehaviorSubject<any> = new BehaviorSubject({})

  wholesale: BehaviorSubject<boolean> = new BehaviorSubject(false)

  openModalProds: BehaviorSubject<boolean> = new BehaviorSubject(false)
  openBannerHeader: BehaviorSubject<boolean> = new BehaviorSubject(false)
  linkBanner: BehaviorSubject<String> = new BehaviorSubject('')

  idxBanner: BehaviorSubject<number> = new BehaviorSubject(0)
  compression:string = ''
  private subscriptions: Array<Subscription> = [];
  language:any = null
  constructor(
    private httpService: HttpService,
    private configGeneral: ConfigGeneralService,
    private http: HttpClient,
    private translate: TranslateService
  ) { }

  setIdx(idx:number){
    this.idxBanner.next(idx)
  }

  changeLng(){
    this.language = JSON.parse(localStorage.getItem('language')) || {}
    let lang = localStorage.getItem('languaje') || 'es'
    console.log(this.language)
    this.translate.getTranslation(lang).subscribe((res) => {
      this.translate.setTranslation(lang, this.language, true);
    });
  }

  getLanguage(lng:string = 'es'){
    return new Promise((resolve, reject) => {
      this.http.get(`${environment.API_URL}lang/${lng}`)
      .subscribe((res:any) => {
        resolve({ data: res, msg: 'success' });
      }, err => {
        reject({ msg: err.error.error });
      })
    })
  }

  getConfigStore(){
    this.subscriptions.push(
      this.configGeneral.getAllConfig()
      .subscribe((config:any[]) => config.map(({name, value}) => name === 'compression' && (this.compression = value)))
    )
  }

  getIdx(): Observable<number> {
    return this.idxBanner
  }

  setOpenBannerHeader(value:boolean){
    this.openBannerHeader.next(value)
  }

  getOpenBannerHeader(): Observable<boolean>{
    return this.openBannerHeader
  }

  setOpenModal(value:boolean){
    this.openModalProds.next(value)
  }

  getOpenModal(): Observable<boolean> {
    return this.openModalProds
  }

  setLink(link:string){
    this.linkBanner.next(link)
  }

  getLink(): Observable<String> {
    return this.linkBanner
  }



  setWholesalesVar(value:boolean){
    this.wholesale.next(value)
  }

  getWholesaleVar(): Observable<any>{
    return this.wholesale
  }

  setSlider(type:string){
    this.slider.next(type)
  }

  getSlider(){
    return this.slider
  }

  uploadImage(data: any, comprimir:boolean) : Promise<any> {
    this.getConfigStore()
    if(!comprimir){
      const formData = new FormData();
      formData.append('file', data);
      formData.append('quality', String(100));
      return this.httpService.postFile(`admin/files`, formData, comprimir)
    }
    if(comprimir){
      const formData = new FormData();
      formData.append('file', data);
      formData.append('quality', this.compression);
      return this.httpService.postFile(`admin/files`, formData, comprimir)
    }
  }

  getLanding() : Promise<any> {
    return this.httpService.get(`home/getHomeTwo`);
  }

  getCatalogo(){
    return this.httpService.get('home/getCatalogo')
  }

  public uploadPdf(data:any){
    const formData = new FormData();
		formData.append('file', data);
		return this.httpService.postFile('admin/home/catalogo', formData);
  }

  uploadToLanding(obj: object){
    return this.httpService.post('admin/home/homeTwo', obj)
  }

  getHome() : Promise<any> {
    return this.httpService.get(`home/getHome`);
  }

  updateHome(data: any) : Promise<any> {
    return this.httpService.post(`admin/home`, {home : data})
  }

  getHomeWholesales(): Promise<any>{
    return this.httpService.get('home/getWholesale')
  }

  updateHomeWholesales(data:any): Promise<any>{
    return this.httpService.post('admin/home/wholesale', {wholesale: data})
  }

  setImgBanner(img){
    this.listImagesBanner.next(img)
  }

  getImgBanner(){
    return this.listImagesBanner
  }

  setImgHeader(images){
    console.log('header')
    this.bannersHeader.next(images)
  }

  setImgMidle(images){
    this.bannersMiddle.next(images)
  }

  setImgEnd(images){
    this.bannersEnd.next(images)
  }

  setLastSection(images){
    this.lastSection.next(images)
  }


  getLastSection(){
    return this.lastSection
  }


  getImagesHeader(){
    return this.bannersHeader
  }

  getImagesMiddle(){
    return this.bannersMiddle
  }

  getImagesEnd(){
    return this.bannersEnd
  }

  setProductSelected(product){
    this.productselected.next(product)
  }

  getProductSelected(){
    return this.productselected
  }

  setProducts(product){
    this.products.next(product)
  }

  getProducts(){
    return this.products
  }

  img(img){
    this.imageSelected.next(img)
  }

  getImg(){
    return this.imageSelected
  }

  setFirstImage(value: boolean){
    this.firstImage.next(value)
  }

  getFirstImage(){
    return this.firstImage
  }


  setFirstImgToUpload(value){
    this.firstImgToUpload.next(value)
  }

  setSecondImgToUpload(value){
    this.secondImgToUpload.next(value)
  }

  getFirstImgToUpload(){
    return this.firstImgToUpload
  }

  getSecondImgToUpload(){
    return this.secondImgToUpload
  }

}
