import { JsonPipe } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'src/app/service/alerts/alerts.service';
import { StoresService } from 'src/app/service/stores/stores.service';

@Component({
  selector: 'app-list-stores',
  templateUrl: './list-stores.component.html',
  styleUrls: ['./list-stores.component.scss']
})
export class ListStoresComponent implements OnInit {

  @Output() filterStore: EventEmitter<any> = new EventEmitter();
  stores: any[] = [];
  currentFilter: any = 'all';
  master_create_users = JSON.parse(localStorage.getItem('master_create_users'))

  constructor(
    private storeService : StoresService,
    private alerts: AlertsService,
    private translate: TranslateService
  ) { 
    // console.log(JSON.parse(localStorage.getItem('role')))
    this.getAllStores()
  }

  ngOnInit(): void {
  }

  getAllStores(){
    let role = JSON.parse(localStorage.getItem('role'))
    this.storeService.getListSelect(role).then(res => {
      if(this.master_create_users == false && res.response.length > 0){
        this.stores = res.response
        this.currentFilter = this.stores[0].id
      }
      else{
        this.stores = [{name: 'Todas', id: 'all'}]
        this.stores = [...this.stores, ...res.response]
        this.currentFilter = 'all'
      }
      this.change(this.stores[0])
    })
    .catch(err => this.alerts.alertError(this.translate.instant('reservation.alerts.error')))
  }

  change(store){
    this.filterStore.emit({store: store.id,sigla:store.sigla})
    this.storeService.setStore(store.id)
  }



}
