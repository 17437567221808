import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  private user: BehaviorSubject<any> = new BehaviorSubject({});
  private store: BehaviorSubject<any> = new BehaviorSubject({});
  private configStore : BehaviorSubject<any[]> = new BehaviorSubject([]);

  constructor(
    private httpService : HttpService
  ) { }

  setUser(user){
    localStorage.setItem('user', JSON.stringify(user))
    this.user.next(user)
  }

  getUser(){
    let user = JSON.parse(localStorage.getItem('user'))
    this.user.next(user)
    return this.user
  }

  setStore(store){
    localStorage.setItem('store', JSON.stringify(store))
    this.store.next(store)
  }

  getStore(){
    let store = JSON.parse(localStorage.getItem('store'))
    this.store.next(store)
    return this.store
  }

  getListPage(page: number = 1,search:any  ="",paginate:any=12) : Promise<any> {

    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    .append('paginate', String(paginate))
    .append('search', String(search));

    return this.httpService.get(`admin/stores/list`,params);
  }


  updateStoreStatusById(id: string|any, status: string) : Promise<any> {
    return this.httpService.put(`admin/stores/${id}/status`, {status: status});
  }

  updateMyStore(store: object) : Promise<any> {
    return this.httpService.put(`admin/stores/updateMyStore`, store);
  }

  updateStore(id: string, store: object) : Promise<any> {
    return this.httpService.put(`admin/stores/${id}`, store);
  }


  getStoreByUsc(usc: string ) : Promise<any> {
    return this.httpService.get(`admin/stores/${usc}/detail`);
  }

  getMyStore() : Promise<any> {
    return this.httpService.get(`admin/stores/mystore`);
  }

  getStoreUsersByUsc(usc: string ) : Promise<any> {
    return this.httpService.get(`admin/stores/${usc}/users`);
  }

  addUser(usc: string, user: object) : Promise<any> {
    return this.httpService.put(`admin/stores/${usc}/addUser`, user);
  }

  

  add(store: object) : Promise<any> {
    return this.httpService.post(`admin/stores`, store);
  }

  addPublic(store: object) : Promise<any> {
    return this.httpService.post(`stores`, store);
  }

  getListSelect(role) : Promise<any> {
    
    if(role == 'master_franchise'){
      let {id_user} = JSON.parse(localStorage.getItem('profile'))
      let params : HttpParams = new HttpParams()
      .append('role', role)
      .append('id_user',id_user)
      return this.httpService.get(`admin/stores/listSelect`,params);

    }
    return this.httpService.get(`admin/stores/listSelect`);

  }

  deleteStore(data:object): Promise<any> {
    return this.httpService.delete('admin/stores/delete', data)
  }

  updatePersonalized(data:object){
    return this.httpService.post('admin/deliveries/updatepersonalized', data)
  }

  updatePersonalizedPayment(data:object){
    return this.httpService.post('admin/payments/updatecustompayment', data)
  }

  getAllConfig(){
    let config = JSON.parse(localStorage.getItem('config_store'))
    this.configStore.next(config)
    return this.configStore
  }


  getConf(id_store:string){
    let params : HttpParams = new HttpParams()
    .append('id_store', String(id_store))
    this.httpService.get('configurations/forstore', params)
    .then(res => {
      localStorage.setItem('config_store', JSON.stringify(res.response))
      this.configStore.next(res.response)
    }).catch(err => {
      console.log(err)
    })
  }

  getDeliveriesFromStore(id:string){
    let params: HttpParams = new HttpParams();
    params = params.append('id', id);
    return this.httpService.get(`delivery/getinputs`, params)
  }

  setInfoUser(user){
    localStorage.setItem('user', JSON.stringify(user))
  }

}
