import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ConfigurationService } from 'src/app/service/configuration/configuration.service';

@Component({
  selector: 'app-tabs-components',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {

  @Output() status: EventEmitter<any> = new EventEmitter();
  @Input() tabsOrder: boolean = false
  @Input() showOrdersTabs: boolean = false
  @Input() tabsOrderWholesale: boolean = false
  @Input() users:boolean = false
  @Input() support:boolean = false
  @Input() campaign:boolean = false
  @Input() clone:boolean = false
  @Input() typeCampaign:string = ''

  all = false
  inactivo = false
  activo = true

  tabs:any =  { id: 0, label: 'Pendientes por Pago', status: 'all', type: '' } //este status es el de pedidos
  tabsWholesales :any = { id: 0, label: 'wholesales.orders.pendientes', status: 'pending' }
  tabsOrdersUsersMain: any = { id: 0, label: 'wholesales.orders.pendientes', status: 'pending' }
  tabsSupportMain:any = { id: 0, label: 'Pendientes', status: 'pending', type: '' }
  tabsCampaingMain:any = { id: 0, label: 'campaignData.uploadFile', status: 'file' }

  tabsOrders:any[] =
  [
    { id: 0, label: 'Pendientes', status: 'pending', type: '' },
    { id: 1, label: 'Pendientes por Envio', status: 'approved', type: 'shipping' },
    { id: 2, label: 'Pendientes por Retiro', status: 'approved', type: 'retired' },
    { id: 3, label: 'Retiradas', status: 'retired', type: '' },
    { id: 4, label: 'Enviadas', status: 'shipping', type: '' },
    { id: 5, label: 'orders.all', status: 'all', type: '' },
  ]

  tabsOrdersWholesale:any[] =
  [
    { id: 0, label: 'wholesales.orders.pendientes', status: 'pending' },
    { id: 1, label: 'wholesales.orders.aprobadas', status: 'approved' },
    { id: 2, label: 'wholesales.orders.enviadas', status: 'shipping' },
  ]

  tabsOrdersUsers:any[] =
  [
    { id: 0, label: 'wholesales.orders.pendientes', status: 'pending' },
    { id: 1, label: 'products.actives', status: 'active' },
    { id: 2, label: 'products.inactives', status: 'inactive' },
  ]

  tabsSupport:any[] = [

    { id: 0, label: 'Pendientes', status: 'pending', type: '' },
    { id: 1, label: 'Rechazadas', status: 'reject' },
    { id: 2, label: 'Fallidas', status: 'fail' },
    { id: 3, label: 'Canceladas', status: 'cancel' },

  ]

  tabsCampaing:any[] = [

    { id: 0, label: 'campaignData.uploadFile', status: 'file' },
    { id: 1, label: 'campaignData.contactos', status: 'contacts' },
    { id: 2, label: 'campaignData.contactProd', status: 'products' },

  ]

  constructor(
    private configurationService: ConfigurationService,
  ) { }

  ngOnInit(): void {
    if(this.clone && this.typeCampaign !== ''){
      this.moveTab(this.typeCampaign)
    }

    this.setShowTabs()
  }


  setShowTabs(){

    this.configurationService.getListByStore(JSON.parse(localStorage.getItem('store')).id)
    .then(res => {
      res.response.map(item =>{

        if(item.name == 'showorderstabs'){
          this.showOrdersTabs = item.value.toLowerCase() === 'true'
        }
        
      })
      
    })
    .catch(err => {
      console.log(err)
    })

  }


  ngDoCheck(){
    this.tabsOrder && this.status.emit(this.tabs)
    this.tabsOrderWholesale && this.status.emit(this.tabsWholesales)
    this.users && this.status.emit(this.tabsOrdersUsersMain)
    this.support && this.status.emit(this.tabsSupportMain)
    !this.clone && this.campaign && this.status.emit(this.tabsCampaingMain)
  }


  moveTab(type:string){

    if(type === 'file'){
      this.tabsCampaingMain = { id: 0, label: 'campaignData.uploadFile', status: 'file' }
      this.clone = !this.clone
    }

    if(type === 'contacts'){
      this.tabsCampaingMain = { id: 1, label: 'campaignData.contactos', status: 'contacts' }
      this.clone = !this.clone
    }
  }

}
