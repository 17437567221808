<div class="z-10 fixed flex flex-col top-0 left-0 w-64 bg-white h-full border-r dark:border-dark-botonera navBotonera">
    <div class="flex items-center justify-center h-auto dark:bg-dark-botonera logoNav overflow-hidden">
        <div class="p-3 w-16 flex-none"><img src="./assets/img/logo_icon.png" alt="" class="max-w-full"></div>
        <div class="flex-1 w-36 md:w-32 mr-3 text-gris-base text-sm titleLogo truncate">
            <span *ngIf='ObjectKeys(store).length > 0' >
                <strong class="text-negro-principal dark:text-white text-base capitalize"> {{store.name}} </strong> 
                <span class="capitalize block">{{store.country}}</span>
            </span>
            <span *ngIf='ObjectKeys(store).length == 0' >
                <strong class="text-negro-principal dark:text-white text-base capitalize"> Admin </strong> 
            </span>
        </div>
        <button class="w-14 h-14 relative transition-colors duration-200 hover:text-principal flex sm:hidden mr-1" (click)="toggleService.toggle()">
            <span class="w-6 h-0.5 bg-gris-base absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform rotate-45 origin-center"></span>
            <span class="w-6 h-0.5 bg-gris-base absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform -rotate-45 origin-center"></span>
        </button>
    </div>
    <nav class="overflow-y-auto overflow-x-hidden flex-grow dark:bg-dark-botonera relative">
        <ul class="flex flex-col py-4 h-full">
            <li class="nav-item" *ngFor="let n of menuItems" (click)="select('main', n.title);" [ngClass]="{active: isActive('main', n.title)}">
                <a (click)="redirect(false, n);(isMobile && n.submenu == 0) && toggleService.comprimida = true" class="botones {{n.badgeClass}}">
                    <span class="inline-flex justify-center items-center"><span [ngClass]="[n.icon]"></span></span>
                    <span class="ml-2 text-sm tracking-wide truncate textNav">{{n.title | translate}}</span>
                </a>
                <div class="panel" *ngIf="n.submenu != 0">
                    <ul class="flex flex-col h-full">
                        <li class="nav-item" *ngFor="let child of n.submenu" [ngClass]="{active: isActive('sub', child.title)}" (click)="select('sub', child.title, $event);">
                            <a class="botones cursor-pointer {{child.badgeClass}}" (click)="redirect(true, child);isMobile && toggleService.comprimida = true">
                                <span class="inline-flex justify-center items-center"><span [ngClass]="[child.icon]"></span></span>
                                <span class="ml-2 text-sm tracking-wide truncate textNav">{{child.title | translate}}</span>
                            </a>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="nav-item w-full mt-5 flex items-end flex-1">
                <a class="botones cursor-pointer w-full" (click)="logout()">
                    <span class="inline-flex justify-center items-center"><span class="icon-icon-56 text-xl p-1"></span></span>
                    <span class="ml-2 text-sm tracking-wide truncate textNav">{{'users.logout' | translate}}</span>
                </a>
            </li>
        </ul>
    </nav>
</div>