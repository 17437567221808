import { Component, HostListener, OnInit } from '@angular/core';
import { ToggleClassService } from '../../../service/toggle/toggle-class.service';

@Component({
  selector: 'app-dashboard-layout',
  templateUrl: './dashboard-layout.component.html',
  styleUrls: ['./dashboard-layout.component.scss']
})
export class DashboardLayoutComponent implements OnInit {
   isMobile:boolean

   public constructor(public toggleService: ToggleClassService) {

  }
  ngOnInit(): void {
    this.mostrarMenu()
  }

  mostrarMenu(){
    //mido el contenedor
    let widthContent = document.documentElement.clientWidth;

    if (widthContent <= 640) {
      this.isMobile = true
      this.toggleService.comprimida = true
    }
    else {
      this.isMobile = false
      this.toggleService.comprimida = false
    }
    
  }

  // @HostListener('window:resize', ['$event'])
  // onWindowResize(event) {
  //   event.target.innerWidth ? (this.toggleService.comprimida = true) : ( this.toggleService.comprimida = false )
  // }

}
