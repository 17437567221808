import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor() { }

  alertError(msg: string){
    Swal.fire({
      title: `${msg}`,
      icon: 'error'
    })
  }

  alertSuccess(msg:string){
    Swal.fire({
      title: `${msg}`,
      icon: 'success'
    })
  }

  alertWarning(item:string){

    return Swal.fire({
      icon: 'warning',
      text: `¿Esta seguro que quiere borrar "${item}" ?`,
      showCancelButton: true,
    })


  }


  alertWarningText(text:string){
    return Swal.fire({
      icon: 'warning',
      title: 'Alert',
      text: `${text}`,
    })
  }
  alertWarningText2(text:string){
    return Swal.fire({
      icon: 'warning',
      title: 'No se encontraron Planes!',
      // text: `${text}`,
    })
  }

  confirmOut(){
    return Swal.fire({
      icon: 'question',
      text: 'Aún no ha guardado, ¿Seguro/a desea salir?',
      showCancelButton: true
    }).then(val => val.value ? true : false)
  }

  existContact(info){
    return Swal.fire({
      icon: 'warning',
      text: `Este número esta asignado a ${info.name} ${info.lastname}`,
      showCancelButton: true,
    })
  }

}
