import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { HomeService } from 'src/app/service/home/home.service';
import { ToggleClassService } from './../../service/toggle/toggle-class.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public activeLang = 'es';
  defaultLn = { lan: 'Es', id: 'es', flag: '/assets/img/flags/es.png' }

  languajes:any[] = [
    { lan: 'Es', id: 'es', flag: '/assets/img/flags/es.png' },
    { lan: 'Pt', id: 'pt', flag: '/assets/img/flags/pt.png' },
    { lan: 'En', id: 'en', flag: '/assets/img/flags/us.png' },
  ]

  url:string = ''
  private subscriptions: Array<Subscription> = [];
  role:string = ''
  public constructor(
    public toggleService: ToggleClassService,
    private translate: TranslateService,
    private router: Router,
    private homeSrv: HomeService
  ) {
    let role = JSON.parse(localStorage.getItem('role'))
    this.role = role
    this.checkLanguaje()
    this.homeSrv.changeLng()
    this.subscriptions.push(
      this.router.events.subscribe((val) => val instanceof NavigationEnd && ( this.url = String(this.router.url.split('/')[1]) ))
    )
  }

  getLang(ln:string){
    this.homeSrv.getLanguage(ln)
    .then((res:any) => {
      localStorage.setItem('language', JSON.stringify(res.data))
      this.homeSrv.changeLng()
    })
    .catch(err => {
      console.log(err)
    })
  }

  ngOnInit(): void {
    console.log(this.url)
  }

  checkLanguaje(){
    let languaje = localStorage.getItem('languaje') || 'es'
    this.activeLang = languaje
    this.translate.use(languaje)
    this.defaultLn = this.languajes.find(flag => flag.id === languaje )
  }

  changeLanguaje(flag){
    localStorage.setItem('languaje', flag.id)
    this.activeLang = flag.id
    this.translate.use(flag.id)
    this.defaultLn = flag
    this.getLang(flag.id)
  }

}
