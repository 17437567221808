import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  constructor(private httpservice: HttpService) { }

  getList(page:number) : Promise<any> {
    let params : HttpParams = new HttpParams()
    .append('page', String(page))
    return this.httpservice.get(`admin/shares/list`,params);
  }

  store(data: any, type:string) : Promise<any> {
    // console.log('send data', data);
    return this.httpservice.post2(`admin/shares`, {id_share_type: type,  json : data});
  }

  delete(store_id:any,share_id: number) : Promise<any> {
    return this.httpservice.post(`admin/shares/${store_id}/trash`,{id:String(share_id)})
  }


  cotizacionStatus(cotizacion_id:any) : Promise<any> {
    return this.httpservice.get(`admin/euro/quote/status/${cotizacion_id}`)
  }
  cotizacionAnular(cotizacion_id:any,share_id:any) : Promise<any> {
    return this.httpservice.post(`admin/euro/quote/cancel`,{shareId:String(share_id),quoteId: String(cotizacion_id)})
  }

}
