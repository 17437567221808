import { ChangeDetectorRef,Component, EventEmitter, OnInit, Output,Input, AfterContentChecked} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AlertsService } from 'src/app/service/alerts/alerts.service';
import { StoresService } from 'src/app/service/stores/stores.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-list-users',
  templateUrl: './list-users.component.html',
  styleUrls: ['./list-users.component.scss']
})
export class ListUsersComponent implements OnInit  {

  @Output() filterUser: EventEmitter<any> = new EventEmitter();
  users: any[] = [{name: 'Todos', id: 'init'}];
  currentFilter: any = 'all';
  peticion:boolean = true;
  private subscriptions: Array<Subscription> = [];
  storeSelected:any = ''
  constructor(
    private storeService : StoresService,
    private alerts: AlertsService,
    private translate: TranslateService,
    private cd: ChangeDetectorRef,

  ) { 
   
  }

  ngOnInit(): void {
    // this.cd.detectChanges();
    let role = JSON.parse(localStorage.getItem('role'))
    if(role != 'store'){
      this.change(this.users[0])
      this.getStoreSelected()
      this.getAllUsers()
    }
    
  }

  getStoreSelected(){
    this.subscriptions.push(
      this.storeService.getStore()
      .subscribe(store => {
        if(this.storeSelected != store){
          this.storeSelected = store
          this.getAllUsers()
        }
      } )
    )
  }

  getAllUsers(){
   this.storeService.getStoreUsersByUsc(this.storeSelected).then((res) => {
    this.users = [{name: 'Todos', id: 'all'}]
    res.response.map(item=> {
      if(item.status == 'active') this.users.push({name:item.username,id:item.code,email:item.email})
    })
    if(res.response.length == 1){
      this.change(this.users[1])
    }else{
      this.change({id: 'init'})
    }
    this.cd.detectChanges();
   }).catch(err => this.alerts.alertError(this.translate.instant('reservation.alerts.error')))
  }

  change(user){
    if(this.storeService.getUser().value != user.id){
      this.filterUser.emit({user: user.id})
      this.storeService.setUser(user.id)
      this.cd.detectChanges();
    }

  }

}
