import { Component, OnInit, Input } from '@angular/core';
import { Router, RouterLinkActive } from '@angular/router';
import { AuthService } from 'src/app/service/auth/auth.service';
import { StorageService } from 'src/app/service/storage/storage.service';
import { ROUTES } from './sidebar-menu-routes.config';
import { ToggleClassService } from './../../service/toggle/toggle-class.service';
import { ConfigurationService } from 'src/app/service/configuration/configuration.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[] = []
  level: number = 0
  role:string = ''
  storeID:string = ''
  store:any = {}
  ObjectKeys = Object.keys
  selected: any = {};
  @Input() isMobile:boolean
  list: any[] = []

  LOGO_DEFAULT = './assets/images/ea.png'

  master_type:number = 0

  rol:string = ''
  constructor(
    private storageService : StorageService,
    private authService: AuthService,
    private router: Router,
    public toggleService: ToggleClassService,
    private configurationService: ConfigurationService,

  ) {

    this.storageService.getRole()
    .subscribe((role: string) => {
      if(role) {
        this.role = role;

      }
    });

    if (localStorage.getItem('store') === undefined || localStorage.getItem('store') === "null") {
      this.storeID ="";
    }else
    {
      this.storeID = JSON.parse(localStorage.getItem('store') || '').id;
      this.store = JSON.parse(localStorage.getItem('store'))
    }
  }

  ngOnInit(): void {
    this.menuItems = ROUTES
    this.menuItems = this.menuItems.filter(item =>  item.role.includes(this.role?.replace(/['"]+/g, '')))
    this.menuItems.map(e => {
      const result:any[] = e.tienda.filter((aux:any) => { return (aux === this.storeID?.replace(/['"]+/g, '') && aux == 'morumbi') || aux === 'all' })
      e.submenu.map(sub => {
        const result2:any[] = sub.role.filter(role => role === this.role.replace(/['"]+/g, ''))
        if(result2.length > 0) sub.badgeClass = 'show'
        else sub.badgeClass = 'notShow'
      })
      if(result.length > 0) e.badgeClass = 'show'
      else e.badgeClass = 'notShow'
    })
    this.selected = this.menuItems[0]
    let menu =  this.menuItems 
    if(this.storeID != '' && this.storeID != undefined){
      this.configurationService.getListByStore(this.storeID)
        .then(res => {
          this.list = res.response          
          let boolsShow = { 
            cotizaciones: false,
            cotizar: false,
            cotizarVehiculos: false,
          }
          this.list.map(item => {
            if(item.name == 'hide-cotizaciones'){
              boolsShow.cotizaciones = item.value.toLowerCase() === 'true'
            }
            if(item.name == 'hide-cotizar'){
              boolsShow.cotizar = item.value.toLowerCase() === 'true'
            }
            if(item.name === 'show-cotizarvehiculos'){
              boolsShow.cotizarVehiculos = item.value.toLowerCase() === 'true'
            }
          })
          menu = menu.map(item=>{
            if(item.title == 'Cotizaciones'){
              item.show = !boolsShow.cotizaciones
            }
            if(item.path == '/compartir/cotizar'){
              item.show = boolsShow.cotizarVehiculos ? false : !boolsShow.cotizar;
            }
            if(item.path == '/compartir/cotizarVehiculos'){
              item.show = boolsShow.cotizarVehiculos
            }
            return item
          })
          this.menuItems = menu.filter(item => item.show || item.show == undefined)
        })
        .catch(err => {
          console.log(err)
        })
    }
    else{
      this.menuItems = menu.filter(item => item.show || item.show == undefined)
    }
    this.master_type = JSON.parse(localStorage.getItem('master_type'))
  }

  selectItem(item){
    var element = document.querySelector(item);
    // element.classList.toggle("active");
    // var elementos = document.querySelectorAll('.active')
    // elementos.forEach(element =>  element.classList.remove('active') )
    var paneles = document.querySelectorAll('.panel')
    paneles.forEach(element =>  {element.classList.remove('border-b'),element.setAttribute("style", "null")} )
  }



  // getConfigList(){
  //   this.configurationService.getListByStore(JSON.parse(localStorage.getItem('store')).id)
  //   .then(res => {
  //     let state = false
  //     let exist = false
  //     res.response.map(item =>{
  //       if(item.name === 'product-has-variant'){
  //         state = item.value.toLowerCase() === 'true'
  //         exist = true
  //       }
  //     })
      
  //   })
  //   .catch(err => {
  //     console.log(err)
  //   })
  // }







  toggleAccordian(data:any) {
    var element = document.querySelector(data);
    // var elementos = document.querySelectorAll('.active')
    var paneles = document.querySelectorAll('.panel')
    var panel = element.nextElementSibling;
    // elementos.forEach(element =>  element.classList.remove('active') )
    paneles.forEach(element =>  {element.classList.remove('border-b');element.setAttribute("style", "null")} )
    element.isActive = !element.isActive;
    //element.classList.toggle("active");

    if(element.isActive) {
      // element.classList.add('active')
      panel.style.maxHeight = panel.scrollHeight + "px";
      panel.classList.add('border-b');
    } else {
      // element.classList.remove('active')
      panel.style.maxHeight = null;
      panel.classList.remove('border-b');
    }
  }



  

  logout(){
    this.authService.doLogout();
    this.router.navigate(['']);
  }

  select(type, item, $event?) {
    this.selected[type] = (this.selected[type] === item ? null : item);
    $event ? $event.stopPropagation() : null;
  }

  isActive(type, item) {
    return this.selected[type] === item;
  }

  redirect(sub:boolean, item){
    item.submenu.length === 0 && (this.router.navigate([`/${item.path}`]))
  }

}
